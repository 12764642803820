.main-navbar{
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .navbar-brand{
        margin: 0;
        padding: 0;
    }
    .navbar-nav{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .nav-item{
            .nav-link{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 1.4px;
                padding: 0;
                margin-right: 47px;
            }
        }
    }
}


@media (max-width:1024px){
    .main-navbar{
        height: auto;
        padding: 16px 0;
    }
    .main-navbar .navbar-nav{
        position: static;
        transform: unset;
    }
    .main-navbar .mobile-set-bg{
        position: static;
        transform: unset;
        border-radius: 15px;
        border: 2px solid rgba(255, 255, 255, 0.10);
        background: rgba(255, 255, 255, 0.02);
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(50px);
        padding: 15px;
    }
    .main-navbar .navbar-nav .nav-item .nav-link{
        margin: 16px auto;
        text-align: center;
    }
    .main-navbar .black-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}