.main-banner{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 152px;
    padding-bottom: 94px;
    position: relative;
    z-index: 99;


    .banner-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        pointer-events: none;
        z-index: -1;
        height: 100%;
    }

    .main-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h6{
            color: #FFF;
            text-align: center;
            font-size: 58px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 20px;
        }
        p{
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 60px;
        }

    }

    .wrapper-img{
        margin-top: 71px;
        img{
            width: 100%;
        }
    }
 
}

@media (max-width:1024px){
    .main-banner{
        padding-top: 120px;
    }
    .main-banner .main-content h6{
        font-size: 32px;
    }
    .main-banner .main-content p{
        font-size: 16px;
    }
    .main-banner .banner-bg{
        object-position: left;
    }
}