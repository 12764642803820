.main-footer{
    padding: 29px 0;
    .parent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-side{
            h6{
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                span{
                    color: #61D290;
                }
            }
        }
        .right-side{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 40px;
            a{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; 
            }
        }
    }
}

@media (max-width:600px){
    .main-footer .parent{
        flex-direction: column;
        gap: 25px;
    }
}